import { useState, useRef } from "react";
import axios from "../../app/api/axios";
import { Button, Input } from "@mui/material";
import {
  countryOptions,
  almaMaterOptions,
  completedStudies,
  ethnicityOptions,
  genderOptions,
  sexualityOptions,
  youTubeRegex,
  twitterRegex,
  instagramRegex,
  wikipediaRegex,
  tiktokRegex,
  individualPageLabels,
} from "../../helpers";
import { confirm } from "../ConfirmationDialog/ConfirmationDialog";
import { useNavigate } from "react-router-dom";
import Select from "react-select-virtualized";
import TextareaAutosize from "react-textarea-autosize";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  selectCurrentToken,
  selectCurrentUserInfo,
} from "../../features/auth/authSlice";
import { useSelector } from "react-redux";

import Tooltip from "@mui/material/Tooltip";

import InfoIcon from "@mui/icons-material/Info";
import SubmissionFormSearchBar from "../SubmissionFormSearchBar/SubmissionFormSearchBar";
import PersonalRelationsBuidler from "../PersonalRelationsBuidler/PersonalRelationsBuidler";
import SourcesBuilder from "../SourcesBuilder/SourcesBuilder";

const NATIONALITY_MAX = 2;
const ALMA_MATER_MAX = 3;

const IndividualSubmissionForm = () => {
  const [nationality, setNationality] = useState([]);
  const [almaMater, setAlmaMater] = useState([]);
  const [studies, setStudies] = useState([]);
  const [image, setImage] = useState(null);
  const [dob, setDob] = useState(null);
  const [dateOfDeath, setDateOfDeath] = useState(null);

  const alsoKnownAsBuilderRef = useRef();

  const navigate = useNavigate();

  const token = useSelector(selectCurrentToken); // our current access token
  const userInfo = useSelector(selectCurrentUserInfo);

  const spouseRef = useRef();
  const relationsInputRef = useRef();
  const sourceBuilderRef = useRef();

  const submitForm = async (e) => {
    e.preventDefault();

    if (!(await confirm("Submit Entry?"))) {
      return;
    }

    let error = false;
    let errorText = "";

    const formData = new FormData(e.target);
    const payload = Object.fromEntries(formData);

    let aliases = alsoKnownAsBuilderRef.current.getValues();
    // if any aliases are empty, don't submit and throw an error
    for (let i = 0; i < aliases.length; i++) {
      if (aliases[i] === "") {
        error = true;
        errorText =
          "Please fill out all alias fields or remove any empty alias fields.";
      }
    }

    let sources = sourceBuilderRef.current.getValues();
    for (let i = 0; i < sources.length; i++) {
      if (sources[i] === "") {
        error = true;
        errorText =
          "Please fill out all source fields or remove any empty source fields.";
      }
    }

    if (error) {
      toast.error(errorText, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "light",
      });
      return;
    } else {
      const fd = new FormData();

      let nativeNameString = payload.nativeName;
      let aliasesString = alsoKnownAsBuilderRef.current.getValues().join("|");
      let sourcesString = sourceBuilderRef.current.getValues().join("|");
      let biographyString = payload.biography;
      let ethnicityString = parseInt(payload.ethnicity);
      let nationalityPayloadString = nationality.map((n) => n.value).join("|");
      let almaMaterString = almaMater.map((n) => n.value).join("|");
      let studiesPayloadString = studies.map((n) => n.value).join("|");
      let relationsPayloadString = relationsInputRef.current.getValues();
      let ethnicityNotesString = payload.ethnicityNotes;
      let spouseString = spouseRef.current.getValues();
      let wikipediaString = payload.wikipedia;
      let youtubeString = payload.youtube;
      let instagramString = payload.instagram;
      let tiktokString = payload.tiktok;
      let twitterString = payload.twitter;
      let personalSiteString = payload.personalSite;
      let genderString = payload.gender ? parseInt(payload.gender) : null;
      let sexualityString = payload.sexuality
        ? parseInt(payload.sexuality)
        : null;
      let dobPayload = payload.dob
        ? new Date(payload.dob).toISOString().split("T")[0]
        : null;
      let dateOfDeathPayload = payload.dateOfDeath
        ? new Date(payload.dateOfDeath).toISOString().split("T")[0]
        : null;

      fd.append("image", image);
      fd.append("name", payload.name); // Always send original name since it's the PK
      fd.append("nativeName", nativeNameString);
      fd.append("aliases", aliasesString);
      fd.append("gender", genderString);
      fd.append("biography", biographyString);
      fd.append("ethnicity", ethnicityString);
      fd.append("nationality", nationalityPayloadString);
      fd.append("almaMater", almaMaterString);
      fd.append("studies", studiesPayloadString);
      fd.append("lgbt", sexualityString);
      fd.append("spouse", spouseString);
      fd.append("relations", relationsPayloadString);
      fd.append("sources", sourcesString);
      fd.append("ethnicityNotes", ethnicityNotesString);
      fd.append("wikipedia", wikipediaString);
      fd.append("youtube", youtubeString);
      fd.append("instagram", instagramString);
      fd.append("tiktok", tiktokString);
      fd.append("twitter", twitterString);
      fd.append("personalSite", personalSiteString);
      fd.append("dob", dobPayload);
      fd.append("dateOfDeath", dateOfDeathPayload);
      fd.append("isNewSubmission", 1);

      if (token) {
        const axiosConfig = {
          headers: {
            authorization: `Bearer ${token}`,
          },
        };

        await axios.post("/submitIndividual", fd, axiosConfig);

        navigate("/");
      } else {
        toast.error("You must be authenticated to submit an entry.", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };

  const onChangeNationality = (value) => {
    let temp;
    if (Array.isArray(value)) {
      temp = value;
    } else {
      temp = [...nationality, value];
    }

    if (nationality.length >= NATIONALITY_MAX) {
      temp = temp.slice(1);
    }
    setNationality(temp);
  };

  const onChangeAlmaMater = (value) => {
    let temp;
    if (Array.isArray(value)) {
      temp = value;
    } else {
      temp = [...almaMater, value];
    }

    if (temp.length > ALMA_MATER_MAX) {
      temp = temp.slice(1);
    }
    setAlmaMater(temp);
  };

  const onChangeStudies = (value) => {
    if (Array.isArray(value)) {
      setStudies(value);
    } else {
      setStudies([...studies, value]);
    }
  };

  return (
    <div className="main-section">
      {!userInfo && (<p>please sign in to make a submission</p>)}
      {userInfo && (
        <form
          className="individuals-page"
          encType="multipart/form-data"
          onSubmit={submitForm}
        >
          <ToastContainer
            position="bottom-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss={false}
            draggable
            pauseOnHover
            theme="light"
          />
          <div className="individual-details">
            <div>
              <Tooltip
                className="tooltip"
                placement="right-start"
                title="The name this person most commonly goes by."
              >
                <InfoIcon />
              </Tooltip>
              <Input
                type="text"
                placeholder="Enter Individual's Name"
                className="individual-submission-name-input"
                required
                style={{
                  fontSize: "175%",
                  fontWeight: "bold",
                  fontFamily: "Quicksand",
                }}
                name="name"
              />
            </div>
            <hr />
            <div className="individual-detail-section">
              <div className="individual-submission-input-section">
                <label>
                  <Tooltip
                    className="tooltip"
                    placement="right-start"
                    title="This individual's birth name (not maiden name). Used if they go by a false name, or stage name (e.g. Lady Gaga = stage name, Caryn Johnson (AKA Whoopi Goldberg) = false name)."
                  >
                    <InfoIcon />
                  </Tooltip>
                  <b>NATIVE NAME:</b>
                  <Input type="text" name="nativeName" />
                </label>
              </div>

              <div className="individual-submission-input-section">
                <Tooltip
                  className="tooltip"
                  placement="right-start"
                  title="Other names this individual is known by. e.g. screen names, former aliases etc. This will make searching for this individual easier."
                >
                  <InfoIcon />
                </Tooltip>
                <b>{individualPageLabels.aliases}</b>
                <SourcesBuilder
                  ref={alsoKnownAsBuilderRef}
                  placeholderText="Alias"
                />
              </div>

              <div>
                <b>BIOGRAPHY:</b>
                <br />
                <TextareaAutosize
                  className="biography-textarea"
                  name="biography"
                  placeholder="Write a brief bio here."
                  minRows={4}
                  maxRows={10}
                />
              </div>

              <div>
                <b>NATIONALITY (MAX 2):</b>
                <Select
                  maxHeight={300}
                  isMulti
                  onChange={onChangeNationality}
                  value={nationality}
                  options={countryOptions}
                  name="nationality"
                />
              </div>

              <div>
                <b>{individualPageLabels.almaMater} (MAX 3)</b>{" "}
                <Select
                  maxHeight={300}
                  isMulti
                  onChange={onChangeAlmaMater}
                  value={almaMater}
                  options={almaMaterOptions}
                  name="almaMater"
                />
              </div>

              <div>
                <Tooltip
                  className="tooltip"
                  placement="right-start"
                  title="If this individual's spouse is already in the data set, select them here. Otherwise, simply type in their name."
                >
                  <InfoIcon />
                </Tooltip>
                <b>SPOUSE: </b>
                <SubmissionFormSearchBar ref={spouseRef} isIndividual={true} />
              </div>
            </div>

            <div className="individual-detail-section">
              <div className="tooltip-div">
                <Tooltip
                  className="tooltip"
                  title="If this individual is a relevant part of an existing study, select it here and they will be added to the study's page."
                >
                  <InfoIcon />
                </Tooltip>
                <h1>{individualPageLabels.studies}</h1>
              </div>
              <hr />
              <div className="individual-detail-section">
                <Select
                  maxHeight={300}
                  onChange={onChangeStudies}
                  value={studies}
                  isMulti
                  options={completedStudies}
                  name="studies"
                />
              </div>
            </div>

            <div className="individual-detail-section">
              <h1>{individualPageLabels.relationsAndAssociates}</h1>
              <hr />
              <div className="individual-detail-section">
                <PersonalRelationsBuidler
                  name="relations"
                  ref={relationsInputRef}
                />
              </div>
            </div>
            <Button
              size="small"
              variant="contained"
              type="submit"
              className="individual-submit-button"
            >
              SUBMIT
            </Button>
          </div>

          <div className="spacer"></div>

          <div className="individual-image-container">
            <img
              className="individual-image"
              src={image ? URL.createObjectURL(image) : null}
              alt=""
            />
            <input
              type="file"
              accept="image/png, image/gif, image/jpeg, image/webp"
              name="image"
              onChange={(e) => {
                // check if the image is below 3MB
                if (e.target.files[0].size > 3000000) {
                  toast.error("Image must be below 3MB", {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "light",
                  });
                  return;
                }
                setImage(e.target.files[0]);
              }}
            />

            {image && (
              <Button
                className="individual-image-revert-button"
                variant="contained"
                size="small"
                onClick={() => setImage(null)}
              >
                {" "}
                Revert Image{" "}
              </Button>
            )}
            <div className="individual-image-details">
              <b>{individualPageLabels.gender}</b>
              <Select maxHeight={300} options={genderOptions} name="gender" />
              <b>{individualPageLabels.sexuality}</b>
              <Select
                maxHeight={300}
                options={sexualityOptions}
                name="sexuality"
              />
              <div>
                <b>{individualPageLabels.dob}</b>
              </div>
              <div>
                <DatePicker
                  selected={dob}
                  onSelect={(date) => setDob(date)}
                  name="dob"
                  className="dob-datepicker"
                />
              </div>
              <Button
                size="small"
                variant="contained"
                onClick={() => setDob(null)}
                className="clear-date-button"
              >
                reset
              </Button>
              <div>
                <b>{individualPageLabels.dateOfDeath}</b>
              </div>
              <div>
                <div>
                  <DatePicker
                    selected={dateOfDeath}
                    onSelect={(date) => setDateOfDeath(date)}
                    name="dateOfDeath"
                    className="dob-datepicker"
                  />
                </div>
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => setDateOfDeath(null)}
                  className="clear-date-button"
                >
                  reset
                </Button>
              </div>
              <b>{individualPageLabels.ethnicity}</b>
              <Select
                required
                maxHeight={300}
                options={ethnicityOptions}
                name="ethnicity"
              />
              <div>
                <b>{individualPageLabels.ethnicityNotes}</b>
                <br />
                <TextareaAutosize
                  className="biography-textarea"
                  name="ethnicityNotes"
                  maxLength={50000}
                  placeholder="Details about the individual's ethnicity. e.g. Italian, Half-Jewish, etc."
                  minRows={3}
                  maxRows={10}
                />
              </div>
              <Tooltip
                className="tooltip"
                placement="right-start"
                title="Sources which prove this person's ethnicity. HIGH QUALITY SOURCES ONLY. Nonsense and low quality sources may invalidate your submission."
              >
                <InfoIcon />
              </Tooltip>
              <div>
                <b>{individualPageLabels.sources}</b>
              </div>
              <SourcesBuilder ref={sourceBuilderRef} placeholderText="Source" />
              <div className="individual-detail-section">
                Wikipedia Link:
                <Input
                  type="text"
                  name="wikipedia"
                  inputProps={{
                    pattern: wikipediaRegex,
                  }}
                />
              </div>
              <div className="individual-detail-section">
                YouTube (or other video) Link:
                <Input
                  type="text"
                  name="youtube"
                  inputProps={{
                    pattern: youTubeRegex,
                  }}
                />
              </div>
              <div className="individual-detail-section">
                X (Twitter) Link:
                <Input
                  type="text"
                  name="twitter"
                  inputProps={{
                    pattern: twitterRegex,
                  }}
                />
              </div>
              <div className="individual-detail-section">
                Instagram Link:
                <Input
                  type="text"
                  name="instagram"
                  inputProps={{
                    pattern: instagramRegex,
                  }}
                />
              </div>
              <div className="individual-detail-section">
                TikTok Link:
                <Input
                  type="text"
                  name="tiktok"
                  inputProps={{
                    pattern: tiktokRegex,
                  }}
                />
              </div>
              <div className="individual-detail-section">
                Personal Website Link:
                <Input type="text" name="personalSite" />
              </div>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default IndividualSubmissionForm;
