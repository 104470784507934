import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import html2canvas from "html2canvas";
import domtoimage from 'dom-to-image';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const modalDescriptionStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
};

const socialShareStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  marginTop: "10px",
};

const buttonStyle = {
  // all should be the same width
  width: "50%",
  marginTop: "10px",
  marginBottom: "10px",
};

const SocialShareModal = ({ open, handleClose, memeName, isCustom }) => {
  const handleDownload = () => {
    const collage = document.querySelector(".collage");

    html2canvas(collage, {
      useCORS: true,
      width: collage.offsetWidth,
      height: collage.offsetHeight,
      scale: 3
    }).then((canvas) => {
      canvas.toBlob((blob) => {
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = `ss_${memeName}_meme.png`;
        link.click();
        URL.revokeObjectURL(url); // Clean up
      });
    });
  };

  const url = window.location.href;
  const text = encodeURIComponent(
    `Check out this open source info table, created by Social Sentinel!`
  );

  const handleShareTwitter = () => {
    const twitterUrl = `https://x.com/share?text=${text}&url=${url}`;
    window.open(twitterUrl, "_blank");
  };

  const handleShareTelegram = () => {
    const telegramUrl = `https://t.me/share/url?url=${url}&text=${text}`;
    window.open(telegramUrl, "_blank");
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={style}>
        <h2 id="modal-title">Share or Download Meme</h2>
        <div
          id="modal-description"
          className="modal-description"
          style={modalDescriptionStyle}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleDownload}
            style={buttonStyle}
          >
            Download Image
          </Button>
          {!isCustom && (
            <div className="social-share" style={socialShareStyle}>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleShareTwitter}
                style={{ ...buttonStyle, width: "100%" }}
              >
                Share on X
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleShareTelegram}
                style={{ ...buttonStyle, width: "100%" }}
              >
                Share on Telegram
              </Button>
            </div>
          )}
        </div>
      </Box>
    </Modal>
  );
};

export default SocialShareModal;
