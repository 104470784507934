import React, { useState, useMemo } from "react";
import axios from "../../app/api/axios";
import {
  getIndividualUnderscored,
  getOrganizationUnderscored,
} from "../../helpers";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import RandomIndividualButton from "../RandomIndividualButton/RandomIndividualButton";
import RandomOrganizationButton from "../RandomOrganizationButton/RandomOrganizationButton";

const SEARCH_URL = "/search/";

const SearchBar = () => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  // const loading = open && options.length === 0 && query.length > 2;
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const getIndividuals = (e) => {
    setLoading(true); 
    let searchTerm = e.target.value;

    if (
      searchTerm === undefined ||
      searchTerm === "" ||
      searchTerm.length < 3
    ) {
      setLoading(false);
      return;
    }
    // remove any slashes from the name
    if (typeof searchTerm !== "string") {
      return;
    }
    searchTerm = searchTerm.replace(/\//g, "");
    searchTerm = searchTerm.replace(/\\/g, "");
    axios
      .get(SEARCH_URL + searchTerm)
      .then((data) => {
        setLoading(false);
        console.log(data.data)
        setOptions(data.data);
      })
      .catch((err) => {
        setLoading(false);

        if (err.response && err.response.status === 404) {
          setOptions(null);
        }
      });
  };

  function debounce(callback, wait) {
    let timerId;
    return function (...args) {
      const context = this;
      if (timerId) clearTimeout(timerId);
      timerId = setTimeout(() => {
        timerId = null;
        callback.apply(context, args);
      }, wait);
    };
  }

  const debouncedResults = useMemo(() => {
    return debounce(getIndividuals, 300);
  }, []);

  const handleOnSelect = (item) => {
    if (item.type === 0) {
      navigate(getIndividualUnderscored(item.name));
    } else {
      navigate(getOrganizationUnderscored(item.name));
    }
  };

  return (
    <div className="search-bar">
      <Autocomplete
        variant="standard"
        size="small"
        sx={{ "& fieldset": { borderRadius: 33 } }}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        isOptionEqualToValue={(option, value) => option.title === value.title}
        // This ensures the component doesn't filter out options that don't match the input.
        // This is the default behavior, but as we'll search on native name in the query and return
        // just 'name', the component would filter these out.
        filterOptions={(options) => options}
        getOptionLabel={(option) => option.name}
        options={options}
        loading={loading}
        openOnFocus={false}
        onInputChange={debouncedResults}
        onChange={(_e, value) => {
          if (value) {
            handleOnSelect(value);
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Enter an individual or organization"
            onMouseDownCapture={(e) => e.stopPropagation()}
            InputProps={{
              ...params.InputProps,
            }}
          />
        )}
      />
      <div className="search-bar-buttons">
        <RandomIndividualButton />
        <RandomOrganizationButton />
      </div>
    </div>
  );
};

export default SearchBar;
